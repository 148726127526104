import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import { Actions } from '@models/enums/Actions';
import AlertCircleFilledIcon from '@assets/icons/dashboard/alert-circle-filled.svg';
import AlertTriangleFilledIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import CheckCircleFilledIcon from '@assets/icons/dashboard/check-circle-filled.svg';
import DownloadIcon from '@assets/icons/dashboard/download.svg';
import EyeIcon from '@assets/icons/dashboard/eye.svg';
import EyeOffIcon from '@assets/icons/dashboard/eye-off.svg';
import FileMinusRedIcon from '@assets/icons/dashboard/file-minus-red.svg';
import FilePlusCircleBlueIcon from '@assets/icons/dashboard/file-plus-circle-blue.svg';
import FilePlusCircleFilledGreenIcon from '@assets/icons/dashboard/file-plus-circle-filled-green.svg';
import { FileUserStatuses } from '@models/enums/FileUserStatuses';
import { IFile } from '@models/interfaces/entities/IFile';
import { IngestionResultStatuses } from '@models/enums/IngestionResultStatuses';
import { PIIScanResultStatuses } from '@models/enums/PIIScanResultStatuses';
import SettingsFilledIcon from '@assets/icons/dashboard/settings-filled.svg';
import TrashIcon from '@assets/icons/dashboard/trash.svg';
import XCircleIcon from '@assets/icons/dashboard/x-circle-red.svg';
import { clsx } from 'clsx';
import moment from 'moment';
import { useMemo } from 'react';
import useStyles from './styles';

export interface IProps {
  id?: string;
  item: IFile;
  draggable: boolean;
  extendedName?: boolean;
  hideUserStatus?: boolean;
  showIngestionStatus?: boolean;
  onDetails: () => void;
  onDownload: () => void;
  onDelete: () => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onIgnore?: () => void;
  onUnignore?: () => void;
}

export const FileCard = ({
  id,
  item,
  draggable,
  extendedName,
  hideUserStatus,
  showIngestionStatus,
  onDetails,
  onDownload,
  onDelete,
  onDragStart,
  onDragEnd,
  onIgnore,
  onUnignore,
}: IProps) => {
  const { classes } = useStyles();

  const itemClass = useMemo(() => {
    if (item.userStatus === FileUserStatuses.accepted) return classes.accepted;
    if (item.userStatus === FileUserStatuses.rejected) return classes.rejected;
    if (
      item.piiScanResultStatus === PIIScanResultStatuses.unscanned ||
      item.piiScanResultStatus === PIIScanResultStatuses.completedWithoutPii ||
      item.piiScanResultStatus === PIIScanResultStatuses.skipped
    )
      return classes.processed;
    if (
      item.piiScanResultStatus === PIIScanResultStatuses.failure ||
      item.piiScanResultStatus === PIIScanResultStatuses.completedWithPii
    )
      return classes.withError;
    if (
      item.piiScanResultStatus === PIIScanResultStatuses.inProgress ||
      item.piiScanResultStatus === PIIScanResultStatuses.queued
    )
      return classes.unprocessed;
  }, [classes, item]);

  const statusLabel = useMemo(() => {
    switch (item.piiScanResultStatus) {
      case PIIScanResultStatuses.inProgress:
      case PIIScanResultStatuses.queued: {
        return 'in progress';
      }
      case PIIScanResultStatuses.skipped: {
        return 'skipped';
      }
      case PIIScanResultStatuses.unscanned: {
        return 'skipped';
      }
      case PIIScanResultStatuses.completedWithoutPii: {
        return 'completed without PII detected';
      }
      case PIIScanResultStatuses.completedWithPii: {
        return 'completed with PII detected';
      }
      case PIIScanResultStatuses.failure: {
        return 'failed';
      }
    }
  }, [item]);

  const fileSizeLabel = useMemo(() => {
    if (item.size === undefined) return;
    let bytes = item.size;
    const thresh = 1024;
    const dp = 2;

    if (bytes < thresh) {
      return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }, [item]);

  const ingestionStatusData = useMemo(() => {
    if (!item.ingestionResultStatus) return null;
    switch (item.ingestionResultStatus) {
      case IngestionResultStatuses.inProgress: {
        return {
          label: 'Queued for ingestion',
          icon: FilePlusCircleBlueIcon,
        };
      }
      case IngestionResultStatuses.complete: {
        return {
          label: 'Successfully ingested',
          icon: FilePlusCircleFilledGreenIcon,
        };
      }
      case IngestionResultStatuses.failure: {
        return {
          label: 'Ingestion failed',
          icon: FileMinusRedIcon,
        };
      }
    }
  }, [item.ingestionResultStatus]);

  return (
    <Box
      data-tour='file-card'
      className={clsx([classes.root, draggable && classes.draggable, itemClass])}
      draggable={draggable}
      id={id || item.id}
      key={item.id}
      onDragEnd={(e) => {
        e.stopPropagation();
        onDragEnd && onDragEnd();
      }}
      onDragStart={(e) => {
        e.stopPropagation();
        onDragStart && onDragStart();
      }}
      data-testid='file-card'
    >
      {item.userStatus && !hideUserStatus && (
        <Tooltip
          title={
            <>
              <Typography variant='caption'>{item.userStatus}</Typography>{' '}
              {item.statusApprover ? (
                <>
                  <Typography variant='caption'>by</Typography>{' '}
                  <Typography variant='caption' className={classes.textBold}>
                    {item.statusApprover}
                  </Typography>
                </>
              ) : (
                <Typography variant='caption'>automatically</Typography>
              )}
            </>
          }
        >
          <Box className={clsx([classes.flex])}>
            {item.userStatus === FileUserStatuses.accepted && (
              <img
                className={classes.statusIcon}
                src={CheckCircleFilledIcon}
                alt='Check'
                data-testid='user-accepted-icon'
                data-tour='user-accepted-icon'
              />
            )}
            {item.userStatus === FileUserStatuses.rejected && (
              <img
                className={classes.statusIcon}
                src={XCircleIcon}
                alt='x'
                data-testid='user-rejected-icon'
              />
            )}
          </Box>
        </Tooltip>
      )}
      {showIngestionStatus && ingestionStatusData && (
        <Tooltip
          title={
            <>
              <Typography variant='caption'>{ingestionStatusData.label}</Typography>
            </>
          }
        >
          <Box className={clsx([classes.flex])}>
            {item.userStatus === FileUserStatuses.accepted && (
              <img src={ingestionStatusData.icon} alt='file' />
            )}
          </Box>
        </Tooltip>
      )}
      {item.links[Actions.updateUserStatus] && (
        <Box className={clsx([classes.flex])}>
          {item.piiScanResultStatus !== PIIScanResultStatuses.failure ? (
            <Tooltip title='Action required'>
              <img
                src={AlertTriangleFilledIcon}
                onClick={() => onDetails()}
                data-testid='warning-button'
                alt='warning'
                className={classes.statusIcon}
                data-tour='action-required'
              />
            </Tooltip>
          ) : (
            <Tooltip title='Scanning failed'>
              <img
                src={AlertCircleFilledIcon}
                alt='failure'
                onClick={() => onDetails()}
                data-testid='failure-button'
                className={classes.statusIcon}
              />
            </Tooltip>
          )}
        </Box>
      )}
      {(item.piiScanResultStatus === PIIScanResultStatuses.queued ||
        item.piiScanResultStatus === PIIScanResultStatuses.inProgress) && (
        <Box className={clsx([classes.flex])}>
          <Tooltip title='File processing is currently underway'>
            <img
              src={SettingsFilledIcon}
              alt='pairing disallowed'
              className={clsx([classes.statusIcon, 'animated'])}
            />
          </Tooltip>
        </Box>
      )}

      <Box
        className={clsx([
          classes.flex,
          classes.flexDirectionColumn,
          classes.jcCenter,
          classes.gap4,
          classes.w100,
        ])}
      >
        <Box className={clsx([classes.flex, classes.gap4, classes.jcSpaceBetween, classes.w100])}>
          <Tooltip
            title={
              <>
                <Box>
                  <Typography variant='caption'>{item.name}</Typography>
                </Box>
                <Box>
                  <Typography variant='caption'>Uploaded by</Typography>{' '}
                  <Typography className={classes.textBold} variant='caption'>
                    {item.uploadedBy}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='caption'>at</Typography>{' '}
                  <Typography className={classes.textBold} variant='caption'>
                    {moment(item.uploaded).format('hh:mma, MM.DD.YYYY')}
                  </Typography>
                </Box>
                {item.size !== undefined && (
                  <Box>
                    <Typography variant='caption'>size</Typography>
                    {' - '}
                    <Typography className={classes.textBold} variant='caption'>
                      {fileSizeLabel}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Divider />
                </Box>

                <Box>
                  <Typography variant='caption'>Scanning</Typography>{' '}
                  <Typography className={classes.textBold} variant='caption'>
                    {statusLabel}
                  </Typography>
                </Box>
              </>
            }
          >
            <Typography
              variant='subtitle2'
              className={classes.title}
              data-testid='name-label'
              data-tour='name'
            >
              {extendedName && item.sourceFileName
                ? `${item.sourceFileName} > ${item.name}`
                : item.name}
            </Typography>
          </Tooltip>
          <Box className={clsx([classes.flex, classes.gap4, classes.jcSpaceBetween])}>
            {item.links[Actions.ignore]?.href && onIgnore && (
              <Tooltip title='Ignore file'>
                <IconButton onClick={() => onIgnore()} data-testid='ignore-button'>
                  <img src={EyeOffIcon} alt='ignore' />
                </IconButton>
              </Tooltip>
            )}
            {item.links[Actions.unignore]?.href && onUnignore && (
              <Tooltip title='Unignore file'>
                <IconButton onClick={() => onUnignore()} data-testid='unignore-button'>
                  <img src={EyeIcon} alt='unignore' />
                </IconButton>
              </Tooltip>
            )}
            {!!item.links[Actions.getContent]?.href && (
              <Tooltip title='Download file'>
                <IconButton
                  onClick={() => onDownload()}
                  data-testid='download-button'
                  data-tour='download'
                >
                  <img src={DownloadIcon} alt='download' />
                </IconButton>
              </Tooltip>
            )}
            {!!item.links[Actions.delete]?.href && (
              <Tooltip title='Delete file'>
                <IconButton
                  onClick={() => onDelete()}
                  data-testid='delete-button'
                  data-tour='delete'
                >
                  <img src={TrashIcon} alt='trash' />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
